<template>
  <div :class="limitFileLength > 1 ? 'upload-wrap' : 'upload-wrap set-width'">
    <div :class="is_default_upload ? 'upload-box default' : 'upload-box'">
      <a-upload
        :custom-request="newCustomRequest"
        :list-type="listType"
        :accept="acceptList"
        :file-list="file_list"
        :show-upload-list="{ showPreviewIcon: isShowPreviewIcon, showRemoveIcon: isShowRemoveIcon }"
        :before-upload="newBeforeUpload"
        :remove="handleRemove"
        @preview="handlePreview"
        @change="handleChange"
      >
        <div v-if="file_list && file_list.length < limitFileLength">
          <a-icon type="plus"></a-icon>
          <div class="ant-upload-text">{{ uploadText }}</div>
        </div>
      </a-upload>
      <div class="progress" v-if="is_progress && limitFileLength < 2">{{ progress }}%</div>
    </div>
    <div class="bottom">
      <span class="upload-title">{{ uploadTitle }}</span>
      <a-switch size="small" v-model="is_vod" v-if="isVod" />
    </div>
  </div>
</template>

<script>
import ossUploadMixin from './ossUploadMixin';
import SparkMD5 from 'spark-md5';
import utils from '@/common/util.js';
import requests from '@/api/BaseAliOss';

export default {
  mixins: [ossUploadMixin],
  props: {
    //允许上传的类型
    acceptList: {
      type: String,
      default: '.png,.jpg,.jpeg,.webp,.mp4,.mov,.m4v',
    },
    //按钮文本
    uploadText: {
      type: String,
      default: '上传',
    },
    uploadTitle: {
      type: String,
      default: '上传',
    },
    //上传的错误提示
    uploadMessage: {
      type: String,
      default: '请上传指定格式的文件',
    },
    //已上传的内容
    hasFileList: {
      type: Array,
      default: null,
    },
    //允许通过的文件大小
    limitSize: {
      type: Number,
      default: 0,
    },
    //允许通过的文件个数
    limitFileLength: {
      type: Number,
      default: 1,
    },
    //是否需要返回时长
    isDuration: {
      type: Boolean,
      default: false,
    },
    //是否需要上传到点播
    isVod: {
      type: Boolean,
      default: false,
    },
    //点播 - 凭证数据
    paramsData: {
      type: Object,
      default: () => {},
    },
    //点播 - 上传视频归属业务信息
    extendData: {
      type: String,
      default: '',
    },
    //点播 - 业务系统对应的clientId，总后台：admin，聚金塔：jjt-admin，百夫长：bfz-admin，风火轮后台：fhl-admin-vod，默认fhl-admin-vod
    clientId: {
      type: String,
      default: 'fhl-admin-vod',
    },
    //是否需要断点续传
    isMultipart: {
      type: Boolean,
      default: false,
    },
    //是否普通上传(需要传fileParams对应的参数)
    isNolmal: {
      type: Boolean,
      default: false,
    },
    /*上传文件需要提交的参数(business_code, need_compress, type)
     * business_code：所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
     * need_compress：是否需要压缩处理-针对图片(1:是  2:否  默认是)
     * type：文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
     */
    fileParams: {
      type: Object,
      default: () => {},
    },
    //是否需要显示预览按钮，默认显示
    isShowPreviewIcon: {
      type: Boolean,
      default: true,
    },
    //是否需要显示删除按钮，默认显示
    isShowRemoveIcon: {
      type: Boolean,
      default: true,
    },
    //显示超过多少个禁止上传
    isMaxTotalLength: {
      type: Boolean,
      default: false,
    },
    //删除时是否需要确认
    confirmDelete: {
      type: Boolean,
      default: false,
    },
    //判断是否能超过当时时长
    maxDuration: {
      type: Number,
      default: 0,
    },
    //判断是否需要请求_initOSSAuth方法，multipartUpload/normalUpload这两个方法需要初始化，其他不需要
    isOssAuth: {
      type: Boolean,
      default: true,
    },
    //类型
    listType: {
      type: String,
      default: 'picture-card',
    },
    // 新上传接口
    isNewCreateVod: {
      type: Boolean,
      default: false,
    },
    // 是否需要限制图片大小
    isLimitImageSize: {
      type: Boolean,
      default: false,
    },
    // 需要限制图片大小
    limitImageSize: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
    beforeUpload: {
      type: Function,
      default: undefined,
    },
    customRequest: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      file_type_list: [],
      file_list: [],
      custom_request_type: 'image',
      thumb_url:
        'http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/adsys/2020/10/xQVHkI8uIrzoICsANthwzqqhZtAUUUmIqUr0uJiX.jpeg',
      thumb_url_pdf:
        'https://content-center-pro.afanticar.com/admin/fhl/image/4889efc40cce425ea4a488b89f024742.webp?size=200*200',
      thumb_url_word:
        'https://content-center-pro.afanticar.com/admin/fhl/image/eec184c83a774fdda1a5d74845ea68a0.webp?size=200*200',
      thumb_url_ppt:
        'https://content-center-pro.afanticar.com/admin/fhl/image/913d2317b0cf42a69e6dd27c83ea31b2.webp?size=200*200',
      thumb_url_excel:
        'https://content-center-pro.afanticar.com/admin/fhl/image/dd05f467ec214f7aa8365a3e07e9c6d5.webp?size=200*200',
      thumb_url_zip:
        'https://content-center-pro.afanticar.com/admin/fhl/image/725a057e6cd9492d857c9c540770885b.webp?size=200*200',
      video_suffix_list: ['mp4', 'mov', 'm4v', 'flv', 'mpeg', '3gp', 'avi'],
      is_vod: true,
      params_data: {
        appId: '', //需要找内容中台确认模块使用类型，必填
        filePath: '', // /路径1/路径2/路径3，必填
        identifier: '', //md5，必填

        // businessCodeId: "", //需要找内容中台确认模块使用id，视频必填
        // fileName: '', //有拓展名的文件名，视频必填
        // fileTitle : '', //文件名，视频必填

        // nameame: '', //有拓展名的文件名，图片必填
        // title : '', //文件名，图片必填
      },

      set_progress: null,
      upload_file_params: {
        business_code: 'bfz', //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 1, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },

      is_default_upload: false,
      is_oss_auth: true,
      is_open_preview: false,
    };
  },
  watch: {
    hasFileList: {
      handler: function (data) {
        console.log(data);
        if (data) {
          this.file_list = data;
        }
      },
      immediate: true,
    },
    paramsData: {
      handler: function (data) {
        if (data) {
          // Object.assign(this.params_data, JSON.parse(JSON.stringify(data)), { extendData: this.extendData, clientId: this.clientId })
          Object.assign(this.params_data, JSON.parse(JSON.stringify(data)));
        }
      },
      immediate: true,
    },
    fileParams: {
      handler: function (data) {
        if (data) {
          Object.assign(this.upload_file_params, JSON.parse(JSON.stringify(data)));
        }
      },
      immediate: true,
    },
    isMaxTotalLength: {
      handler: function (data) {
        this.is_default_upload = data;
      },
      immediate: true,
    },
    isOssAuth: {
      handler: function (data) {
        this.is_oss_auth = data;
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    async newCustomRequest(option) {
      this.is_progress = true;
      const { file, onSuccess, onProgress } = option;
      if (this.customRequest) {
        this.onProgress(file, onProgress);
        await this.customRequest(option);
        this.is_progress = false;
      } else {
        /* 1.如果是需要分片上传的话，走multipartUpload
         *  2.video默认走vodUpload，其他默认走normalUploadFile
         *  3.如果设置了 is_vod=false，则都走normalUploadFile
         *  4.如果设置了isNolmal=true，则走normalUploadFile
         */
        if (this.isMultipart) {
          await this.multipartUpload(file, onSuccess).then((res) => {
            this.is_progress = false;
          });
        } else {
          if (
            this.custom_request_type === 'image' ||
            this.custom_request_type === 'file' ||
            !this.is_vod ||
            this.isNolmal
          ) {
            this.onProgress(file, onProgress);

            await this.normalUploadFile(file, onSuccess);
            this.is_progress = false;
          } else {
            await this.vodUpload(file, onSuccess, onProgress).then((res) => {
              this.is_progress = false;
            });
          }
        }
      }
    },
    //设置进度条
    onProgress(file, onProgress) {
      let progress = { percent: 0 };
      let speed = 100 / (file.size / 65000); //上传速度
      let end = Math.round(Math.random() * (99 - 90)) + 90; //生成等待上传成功剩余进度的随机数
      let percent = speed > end ? end : speed;

      this.set_progress = setInterval(() => {
        if (progress.percent + percent <= end) {
          progress.percent += percent;
        } else {
          progress.percent = end;
          clearInterval(this.set_progress);
        }

        onProgress(progress);
        this.progress = progress.percent.toFixed(0);
      }, 100);
    },
    newBeforeUpload(file) {
      this.beforeUpload && this.beforeUpload(file);
      return new Promise((resolve, reject) => {
        if (this.isMaxTotalLength) {
          return reject(false);
        }

        const file_type = file.type.split('/')[0];
        const name_list = file.name.split('.');
        const type = name_list[name_list.length - 1];
        const accept_list = this.acceptList.replaceAll('.', '').split(',');
        let upload_state = false;

        for (let item of accept_list) {
          if (type.toLocaleLowerCase() === item.trim()) {
            upload_state = true;
            break;
          }
        }
        if (this.limitSize) {
          const isLimit = file.size / 1024 / 1024 < this.limitSize;
          if (!isLimit) {
            this.$message.error(`文件格式不超过${this.limitSize}MB!`);
            return reject(false);
          }
        }
        if (!upload_state) {
          this.$message.error(this.uploadMessage);
          return reject(false);
        }
        file.suffix = type;
        const appSuxWhiteList = ['zip'] //application类型排除列表 旧application相关逻辑会导致获取不到文件名oss失败
        if (file_type === 'image') {
          this.custom_request_type = 'image';

          if (this.isNolmal && !this.fileParams) {
            this.upload_file_params.need_compress = 1;
            this.upload_file_params.type = 1;
          }
          if (type == 'gif') {
            this.upload_file_params.need_compress = 2;
          }

          this.params_data.name = file.name;
          this.params_data.title = file.name.split('.')[0];

          let that = this;

          this.setLimitImageSize(file).then(() => {
            let fileReader = new FileReader();
            let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
            fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
            fileReader.onload = function (e) {
              spark.append(e.target.result);
              let md5 = spark.end();
              file.md5 = md5;

              that.params_data.identifier = md5;

              if (this.isDuration) {
                file.duration = 1;
                return resolve(true);
              } else {
                return resolve(true);
              }
            };
          });
        } else if (file_type === 'video') {
          this.custom_request_type = 'video';

          this.params_data.fileName = file.name;
          this.params_data.fileTitle = file.name.split('.')[0];

          if (this.isNolmal && !this.fileParams) {
            this.upload_file_params.need_compress = 2;
            this.upload_file_params.type = 3;
          }

          this.$emit('changeFile', this.file_list.length + 1);

          let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
          let fileReader = new FileReader();
          let that = this;
          fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
          fileReader.onload = function (e) {
            spark.append(e.target.result);
            let md5 = spark.end();
            file.md5 = md5;

            that.params_data.identifier = md5;

            //如需获取时长，从file的duration取值
            //时长范围为1-20s
            if (that.isDuration) {
              that.getTimes(file).then((res) => {
                console.log('res', res);
                file.duration = res > 1 ? res : 1;

                if (that.maxDuration && res < that.maxDuration - 0.5) {
                  that.$message.error(`上传文件的时长不能小于${that.maxDuration - 0.5}秒，当前时长为${res}秒`);
                  return reject(false);
                } else {
                  return resolve(true);
                }
              });
            } else {
              return resolve(true);
            }
          };
        } else if (file_type === 'application' && appSuxWhiteList.indexOf(file.suffix) === -1){
          this.custom_request_type = 'file';
          this.upload_file_params.type = 7;
          return resolve(true);
        } else {
          this.params_data.name = file.name;
          this.params_data.title = file.name.split('.')[0];
          this.upload_file_params.need_compress = 2;
          this.custom_request_type = 'file';
          this.upload_file_params.type = 7;
          let fileReader = new FileReader();
          let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
          let that = this;
          fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
          fileReader.onload = function (e) {
            spark.append(e.target.result);
            let md5 = spark.end();
            file.md5 = md5;

            that.params_data.identifier = md5;

            if (this.isDuration) {
              file.duration = 1;
              return resolve(true);
            } else {
              return resolve(true);
            }
          };
        }
      });
    },
    // 判断图片限制
    setLimitImageSize(file) {
      return new Promise((resolve, reject) => {
        if (this.isLimitImageSize) {
          let that = this;
          let imgReader = new FileReader();
          imgReader.readAsDataURL(file); //file就是获取到的文件 //异步执行函数
          imgReader.onload = function (e) {
            console.log({ e });
            let result = e.target.result;
            var imageObj = new Image();
            imageObj.src = result;
            imageObj.onload = function () {
              console.log('imageObj', imageObj.width, imageObj.height);
              if (that.limitImageSize.width == imageObj.width || that.limitImageSize.height == imageObj.height) {
                return resolve(true);
              } else {
                that.$message.error(`上传尺寸必须为${that.limitImageSize.width}*${that.limitImageSize.height}`);
                return reject(false);
              }
            };
          };
        } else {
          return resolve(true);
        }
      });
    },
    //获取时长
    getTimes(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);

      return new Promise((resolve, reject) => {
        audioElement.addEventListener('loadedmetadata', function () {
          console.log(audioElement.duration, audioElement.currentTime);
          resolve(audioElement.duration);
        });
      });
    },
    handleChange(info) {
      // console.log('info', info, info.file.status, info.fileList);
      if (info.file.status) {
        this.file_list = info.fileList;
        if (info.file.status === 'uploading') {
          this.is_default_upload = true;
        } else if (info.file.status === 'done') {
          this.is_default_upload = this.isMaxTotalLength;

          let fileList = [...info.fileList];
          fileList = fileList.slice(-this.limitFileLength);

          let arr = [];
          fileList.map((file) => {
            // console.log('handleChange', file);
            // file.thumbUrl = this.$utils.fileSuffixTypeUtil(file.xhr || file.url, this.video_suffix_list) ? this.thumb_url : file.xhr
            file.thumbUrl = utils.fileSuffixTypeUtil(file.xhr || file.url, this.video_suffix_list)
              ? this.thumb_url
              : this.filterThumb(file);

            arr.push({ progress: 0, is_progress: false });
          });

          if (this.custom_request_type === 'image' || !this.is_vod || this.isNolmal) {
            this.progress = 0;
            this.is_progress = false;
            clearInterval(this.set_progress);
          }

          this.file_list = fileList;
          this.$emit('handleUrl', info.file);
        } else if (info.file.status === 'removed') {
          this.$emit('handleUrl', '');
        } else if (info.file.status === 'error') {
          this.$emit('changeFile', this.file_list.length - 1);
        }
      } else {
        return;
      }
    },
    filterThumb(file) {
      const type = file.suffix;
      let thumbUrl = file.xhr;
      this.is_open_preview = true;

      switch (type) {
        case 'pdf':
          thumbUrl = this.thumb_url_pdf;
          break;
        case 'doc':
        case 'docx':
          thumbUrl = this.thumb_url_word;
          break;
        case 'ppt':
        case 'pptx':
          thumbUrl = this.thumb_url_ppt;
          break;
        case 'xls':
        case 'xlsx':
          thumbUrl = this.thumb_url_excel;
          break;
        case 'zip':
          thumbUrl = this.thumb_url_zip;
          break;
        default:
          this.is_open_preview = false;
      }

      return thumbUrl;
    },
    async handlePreview(file) {
      let url = file.xhr || file.url;

      if (this.is_open_preview) {
        window.open(url);
      } else {
        this.$emit('handlePreview', url);
      }
    },
    handleRemove(info) {
      let that = this;
      if (that.confirmDelete) {
        return new Promise((resolve, reject) => {
          that.$confirm({
            title: '确定删除该文件？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              that.$emit('changeFile', that.file_list.length - 1);
              return resolve(true);
            },
            onCancel() {
              return resolve(false);
            },
          });
        });
      } else {
        that.$emit('changeFile', that.file_list.length - 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.upload-wrap {
  position: relative;
  &.set-width {
    width: 105px;
  }

  .upload-box {
    position: relative;
  }
  .progress {
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 60px;
    left: 10px;
    z-index: 1000;
  }
  .bottom {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

/deep/ .default .ant-upload-select {
  pointer-events: none;
}
</style>
